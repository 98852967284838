import { Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import BarLoader from 'react-bar-loader';
import { useLetterContext } from '../contexts/LetterContext';

const Landing = lazy(() => import('../screens/Landing'));
const Write = lazy(() => import('../screens/Write'));
const Envelope = lazy(() => import('../screens/Envelope'));
const Stamp = lazy(() => import('../screens/Stamp'));
const Complete = lazy(() => import('../screens/Complete'));

const Router = () => {
  const { receiver, text, envelope } = useLetterContext();

  return (
    <Suspense fallback={<BarLoader width="100%" color="#14C0DC" height={5} />}>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route
          path="/write"
          element={!receiver?._id ? <Navigate to="/" /> : <Write />}
        />
        <Route
          path="/envelope"
          element={
            !text ? (
              <Navigate to={!receiver?._id ? '/' : '/write'} />
            ) : (
              <Envelope />
            )
          }
        />
        <Route
          path="/stamp"
          element={
            !envelope?.envelope ? (
              <Navigate
                to={!receiver?._id ? '/' : !text ? '/write' : '/envelope'}
              />
            ) : (
              <Stamp />
            )
          }
        />
        <Route path="/complete" element={<Complete />} />
        <Route path="/:id" element={<Landing />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
