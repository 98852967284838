import { gql } from '@apollo/client';

const EnvelopeQueries = {
  GET: () => gql`
    query GetEnvelopes(
      $first: Int
      $from: Int
      $filters: FilterEnvelopeFields
    ) {
      Envelopes(first: $first, from: $from, status: active, filters: $filters) {
        _id
        random_id
        name
        is_active
        is_premium
        coin
        envelope
      }
    }
  `,

  COUNT: () => gql`
    query CountEnvelopes($filters: FilterEnvelopeFields) {
      EnvelopesCount(status: all, filters: $filters)
    }
  `,

  UPDATE: () => gql`
    mutation UpdateEnvelope($_id: ObjectID!, $data: UpdateEnvelopeData!) {
      updateEnvelope(_id: $_id, data: $data) {
        _id
        random_id
        name
        is_active
        is_premium
        coin
      }
    }
  `,

  CREATE: () => gql`
    mutation AddEnvelope(
      $random_id: ID
      $name: String!
      $is_active: Boolean
      $is_premium: Boolean
      $coin: Int
      $envelope: Upload!
    ) {
      addEnvelope(
        random_id: $random_id
        name: $name
        is_active: $is_active
        is_premium: $is_premium
        coin: $coin
        envelope: $envelope
      ) {
        _id
        random_id
        name
        is_active
        is_premium
        coin
        envelope
      }
    }
  `,

  DELETE: () => gql`
    mutation DeleteEnvelope($_id: ObjectID!) {
      deleteEnvelope(_id: $_id) {
        ok
      }
    }
  `,
};

export default EnvelopeQueries;
