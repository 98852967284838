import logoImage from '../../assets/images/logo.png';

const Header = () => {
  return (
    <header className="bg-lightGray z-10 hidden p-2 shadow-md md:block">
      <div className="mx-2">
        <img src={logoImage} alt="Letterbird" />
      </div>
    </header>
  );
};

export default Header;
