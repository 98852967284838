import { gql } from '@apollo/client';

const ColorQueries = {
  GET: () => gql`
    query GetColors($first: Int, $from: Int, $filters: FilterColorFields) {
      Colors(first: $first, from: $from, status: active, filters: $filters) {
        _id
        name
        web
        is_active
        is_premium
      }
    }
  `,

  COUNT: () => gql`
    query CountColors($filters: FilterColorFields) {
      ColorsCount(status: all, filters: $filters)
    }
  `,

  UPDATE: () => gql`
    mutation UpdateColor($_id: ObjectID!, $data: UpdateColorData!) {
      updateColor(_id: $_id, data: $data) {
        _id
        name
        web
        is_active
        is_premium
      }
    }
  `,

  CREATE: () => gql`
    mutation AddColor($hex: String!, $name: String!, $is_premium: Boolean) {
      addColor(hex: $hex, name: $name, is_premium: $is_premium) {
        _id
        name
        web
        is_active
        is_premium
      }
    }
  `,

  DELETE: () => gql`
    mutation DeleteColor($_id: ObjectID!) {
      deleteColor(_id: $_id) {
        ok
      }
    }
  `,
};

export default ColorQueries;
