import { gql } from '@apollo/client';

const StampQueries = {
  GET: () => gql`
    query GetStamps($first: Int, $from: Int, $filters: FilterStampFields) {
      Stamps(first: $first, from: $from, status: active, filters: $filters) {
        _id
        random_id
        name
        is_active
        is_premium
        coin
        stamp
      }
    }
  `,

  COUNT: () => gql`
    query CountStamps($filters: FilterStampFields) {
      StampsCount(status: all, filters: $filters)
    }
  `,

  UPDATE: () => gql`
    mutation updateStamp($_id: ObjectID!, $data: UpdateStampData!) {
      updateStamp(_id: $_id, data: $data) {
        _id
        random_id
        name
        is_active
        is_premium
        coin
      }
    }
  `,

  CREATE: () => gql`
    mutation AddStamp(
      $random_id: ID
      $name: String!
      $is_active: Boolean
      $is_premium: Boolean
      $coin: Int
      $stamp: Upload!
    ) {
      addStamp(
        random_id: $random_id
        name: $name
        is_active: $is_active
        is_premium: $is_premium
        coin: $coin
        stamp: $stamp
      ) {
        _id
        random_id
        name
        is_active
        is_premium
        coin
        stamp
      }
    }
  `,

  DELETE: () => gql`
    mutation DeleteStamp($_id: ObjectID!) {
      deleteStamp(_id: $_id) {
        ok
      }
    }
  `,
};

export default StampQueries;
