import { gql } from '@apollo/client';

const LetterQueries = {
  GET: () => gql`
    query GetLetters($first: Int, $from: Int, $filters: FilterLetterFields) {
      Letters(first: $first, from: $from, status: all, filters: $filters) {
        _id
        random_id
        sender {
          _id
          user_pin
        }
        reciever {
          _id
          user_pin
        }
        send_time
        delevery_time
        is_read
        read_time
        is_replied
        is_favourite_by_reciever
        is_favourite_by_sender
        is_deleted_by_sender
        is_deleted_by_reciever
        is_reported
        with_match
        express_delivery
        color {
          name
        }
        paper {
          name
          paper
        }
        font {
          google_name
        }
        envelope {
          name
          envelope
        }
        stamp {
          name
          stamp
        }
        read_notification
      }
    }
  `,

  COUNT: () => gql`
    query CountLetters($filters: FilterLetterFields) {
      LettersCount(status: all, filters: $filters)
    }
  `,

  CREATE: () => gql`
    mutation AddLetter(
      $random_id: ID!
      $reciever_id: ObjectID!
      $envelope_id: ObjectID
      $stamp_id: ObjectID
      $paper_id: ObjectID
      $font_id: ObjectID!
      $color_id: ObjectID!
      $text: String!
    ) {
      addLetter(
        random_id: $random_id
        reciever_id: $reciever_id
        envelope_id: $envelope_id
        stamp_id: $stamp_id
        paper_id: $paper_id
        font_id: $font_id
        color_id: $color_id
        text: $text
        is_anonymous: true
      ) {
        _id
      }
    }
  `,

  STATS: () => gql`
    query {
      LettersStats {
        total {
          sent
          unread
        }
        today {
          sent
          unread
        }
        month {
          sent
          unread
        }
        week {
          sent
          unread
        }
      }
    }
  `,

  CHART_STATS: () => gql`
    query {
      LetterChartStats {
        dates
        letters
        unread
      }
    }
  `,
};

export default LetterQueries;
