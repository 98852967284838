import { gql } from '@apollo/client';

const PaperQueries = {
  GET: () => gql`
    query GetPapers($first: Int, $from: Int, $filters: FilterPaperFields) {
      Papers(first: $first, from: $from, status: active, filters: $filters) {
        _id
        random_id
        name
        is_active
        is_premium
        # coin
        paper
      }
    }
  `,

  COUNT: () => gql`
    query CountPapers($filters: FilterPaperFields) {
      PapersCount(status: all, filters: $filters)
    }
  `,

  UPDATE: () => gql`
    mutation UpdatePaper($_id: ObjectID!, $data: UpdatePaperData!) {
      updatePaper(_id: $_id, data: $data) {
        _id
        random_id
        name
        is_active
        is_premium
        # coin
      }
    }
  `,

  CREATE: () => gql`
    mutation AddPaper(
      $random_id: ID
      $name: String!
      $is_active: Boolean
      $is_premium: Boolean
      #$coin: Int
      $paper: Upload!
    ) {
      addPaper(
        random_id: $random_id
        name: $name
        is_active: $is_active
        is_premium: $is_premium
        #coin: $coin
        paper: $paper
      ) {
        _id
        random_id
        name
        is_active
        is_premium
        # coin
        paper
      }
    }
  `,

  DELETE: () => gql`
    mutation DeletePaper($_id: ObjectID!) {
      deletePaper(_id: $_id) {
        ok
      }
    }
  `,
};

export default PaperQueries;
