import { gql } from "@apollo/client";

const UserQueries = {
  GET: () => gql`
    query GetUsers($first: Int, $from: Int, $filters: FilterUserFields) {
      Users(first: $first, from: $from, status: all, filters: $filters) {
        _id
        user_id
        user_pin
        # name
        # surname
        # email
        # phone_number
        is_active
        is_deactivated
        is_deleted
        is_premium
        is_banned
        # total_coins
        native_language {
          language {
            name
          }
        }
        speaking_languages {
          language {
            name
          }
          stars
        }
        biography {
          date_of_birth
          country {
            country
          }
          city {
            name
          }
          gender {
            name
          }
        }
        account_setting {
          accept_annonymous_letter
          # accept_random
          # allow_notification
          # account_type
        }
        # letters_info {
        # sent
        # received
        # unread
        # favourite
        # drafted
        # deleted
        # }
        # sign_up_date
        # last_sign_in
        # premium_start
        # premium_duration
      }
    }
  `,

  GET_ONE: () => gql`
    query GetUser($id: String!) {
      User(lookupType: Anonymous, lookup: $id) {
        _id
        user_id
        user_pin
        # name
        # surname
        # email
        # phone_number
        is_active
        is_deactivated
        is_deleted
        is_premium
        is_banned
        # total_coins
        native_language {
          language {
            name
          }
        }
        speaking_languages {
          language {
            name
          }
          stars
        }
        biography {
          date_of_birth
          address
          country {
            country
          }
          city {
            name
          }
          gender {
            name
          }
          about
        }
        account_setting {
          accept_annonymous_letter
          annoymous_letter_limit
          # accept_random
          # allow_notification
          # account_type
          # accept_real_letters_and_gifts
          # keep_in_match
        }
        # letters_info {
        #   sent
        #   received
        #   unread
        #   favourite
        #   drafted
        #   deleted
        # }
        # sign_up_date
        # last_sign_in
        # premium_start
        # premium_duration
        interests {
          name
        }
        avatar {
          avatar
        }
        match_settings {
          range {
            min
            max
          }
          genders {
            name
          }
          native
          country
        }
        # blocked_users {
        #   user_pin
        # }
      }
    }
  `,

  COUNT: () => gql`
    query CountAvatars($filters: FilterUserFields) {
      UsersCount(status: all, filters: $filters)
    }
  `,

  UPDATE: () => gql`
    mutation UpdateUser($_id: ObjectID!, $data: UpdateUserData!) {
      updateUser(_id: $_id, data: $data) {
        _id
        user_id
        user_pin
        # name
        # surname
        # email
        # phone_number
        is_active
        is_deactivated
        is_deleted
        is_premium
        is_banned
        # total_coins
        native_language {
          language {
            name
          }
        }
        speaking_languages {
          language {
            name
          }
        }
        biography {
          date_of_birth
          country {
            country
          }
          city {
            name
          }
          gender {
            name
          }
        }
        account_setting {
          accept_annonymous_letter
          # accept_random
          # allow_notification
          # account_type
        }
        # letters_info {
        #   sent
        #   received
        #   unread
        #   favourite
        #   drafted
        #   deleted
        # }
        # sign_up_date
        # last_sign_in
      }
    }
  `,

  STATS: () => gql`
    query {
      UsersStats {
        total
        today
        month
        week
      }
    }
  `,
};

export default UserQueries;
