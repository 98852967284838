import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
} from "@apollo/client";
import { onError } from "apollo-link-error";
import { ToastContainer } from "react-toastify";
import { LetterProvider } from "./contexts/LetterContext";
import "react-toastify/dist/ReactToastify.css";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL,
  //  uri: "https://5e4d-39-61-53-80.eu.ngrok.io",
  //uri: "https://api.letterbird.app/",
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, httpLink]),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <LetterProvider>
        <App />
      </LetterProvider>
      <ToastContainer />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
