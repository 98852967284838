import { BrowserRouter } from 'react-router-dom';
import Header from './components/Header';
import Router from './routes';

function App() {
  return (
    <div className="bg-lightGray font-kufam flex h-screen w-full flex-col">
      <BrowserRouter>
        <Header />
        <Router />
      </BrowserRouter>
    </div>
  );
}

export default App;
