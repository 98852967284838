import { useState, useEffect, useContext, createContext } from "react";
import { useMutation } from "@apollo/client";
import { LetterQueries } from "../api/queries";
import { toast } from "react-toastify";

const LetterContext = createContext();

export const useLetterContext = () => {
  const {
    receiver,
    setReceiver,
    resetReceiver,
    color,
    setColor,
    resetColor,
    paper,
    setPaper,
    resetPaper,
    font,
    setFont,
    resetFont,
    text,
    setText,
    resetText,
    envelope,
    setEnvelope,
    resetEnvelope,
    stamp,
    setStamp,
    resetStamp,
    resetAll,
    sendLetter,
    sendLoading,
    sendError,
    sendData,
  } = useContext(LetterContext);

  return {
    receiver,
    setReceiver,
    resetReceiver,
    color,
    setColor,
    resetColor,
    paper,
    setPaper,
    resetPaper,
    font,
    setFont,
    resetFont,
    text,
    setText,
    resetText,
    envelope,
    setEnvelope,
    resetEnvelope,
    stamp,
    setStamp,
    resetStamp,
    resetAll,
    sendLetter,
    sendLoading,
    sendError,
    sendData,
  };
};

export const LetterProvider = ({ children }) => {
  // load everything from sessionStorage on first load, if any
  const loadReceiver = () => {
    const receiver = JSON.parse(sessionStorage.getItem("receiver"));
    console.log("receiver", receiver);
    return receiver || {};
  };
  const loadColor = () => {
    const color = JSON.parse(sessionStorage.getItem("color"));
    return color || { web: "#000000" };
  };
  const loadPaper = () => {
    const paper = JSON.parse(sessionStorage.getItem("paper"));
    return paper || {};
  };
  const loadFont = () => {
    const font = JSON.parse(sessionStorage.getItem("font"));
    return font || {};
  };
  const loadText = () => {
    const text = sessionStorage.getItem("text");
    return text || "";
  };
  const loadEnvelope = () => {
    const envelope = JSON.parse(sessionStorage.getItem("envelope"));
    return envelope || {};
  };
  const loadStamp = () => {
    const stamp = JSON.parse(sessionStorage.getItem("stamp"));
    return stamp || {};
  };

  const [receiver, setReceiver] = useState(loadReceiver());
  const [color, setColor] = useState(loadColor());
  const [paper, setPaper] = useState(loadPaper());
  const [font, setFont] = useState(loadFont());
  const [text, setText] = useState(loadText());
  const [envelope, setEnvelope] = useState(loadEnvelope());
  const [stamp, setStamp] = useState(loadStamp());
  const [sendData, setSendData] = useState(null);

  // set everything in session storage to recover on refresh
  useEffect(() => {
    sessionStorage.setItem("receiver", JSON.stringify(receiver));
  }, [receiver]);
  useEffect(() => {
    sessionStorage.setItem("color", JSON.stringify(color));
  }, [color]);
  useEffect(() => {
    sessionStorage.setItem("paper", JSON.stringify(paper));
  }, [paper]);
  useEffect(() => {
    sessionStorage.setItem("font", JSON.stringify(font));
  }, [font]);
  useEffect(() => {
    sessionStorage.setItem("text", text);
  }, [text]);
  useEffect(() => {
    sessionStorage.setItem("envelope", JSON.stringify(envelope));
  }, [envelope]);
  useEffect(() => {
    sessionStorage.setItem("stamp", JSON.stringify(stamp));
  }, [stamp]);

  // reset functions
  const resetReceiver = () => {
    setReceiver({});
  };
  const resetColor = () => {
    setColor({ web: "#000000" });
  };
  const resetPaper = () => {
    setPaper({});
  };
  const resetFont = () => {
    setFont({});
  };
  const resetText = () => {
    setText("");
  };
  const resetEnvelope = () => {
    setEnvelope({});
  };
  const resetStamp = () => {
    setStamp({});
  };
  const resetSendData = () => {
    setSendData(null);
  };
  const resetAll = () => {
    resetReceiver();
    resetColor();
    resetPaper();
    resetFont();
    resetText();
    resetEnvelope();
    resetStamp();
    resetSendData();
  };

  const [createLetter, { loading, error, data }] = useMutation(
    LetterQueries.CREATE(),
    {
      onCompleted(data) {
        if (data) {
          // toast.success('Letter Sent!', {
          //   autoClose: 3000,
          // });

          setSendData(data);
        }
      },
      onError(error) {
        toast.error(error.message, {
          autoClose: 3000,
        });
      },
    }
  );

  return (
    <LetterContext.Provider
      value={{
        receiver,
        setReceiver,
        resetReceiver,
        color,
        setColor,
        resetColor,
        paper,
        setPaper,
        resetPaper,
        font,
        setFont,
        resetFont,
        text,
        setText,
        resetText,
        envelope,
        setEnvelope,
        resetEnvelope,
        stamp,
        setStamp,
        resetStamp,
        resetAll,
        sendLetter: () =>
          createLetter({
            variables: {
              random_id: randomIdGenerator(),
              reciever_id: receiver._id,
              envelope_id: envelope._id,
              stamp_id: stamp._id,
              paper_id: paper._id,
              font_id: font._id,
              color_id: color._id,
              text,
            },
          }),
        sendLoading: loading,
        sendError: error,
        sendData,
      }}
    >
      {children}
    </LetterContext.Provider>
  );
};

const randomIdGenerator = (digits = 6) => {
  let id = "";

  for (let i = 0; i < digits; i++) {
    id += Math.floor(Math.random() * 10);
  }

  return id;
};

export default randomIdGenerator;
