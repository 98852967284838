import { gql } from '@apollo/client';

const FontQueries = {
  GET: () => gql`
    query GetFonts($first: Int, $from: Int, $filters: FilterFontFields) {
      Fonts(first: $first, from: $from, status: active, filters: $filters) {
        _id
        random_id
        google_name
        type
        is_premium
        is_active
      }
    }
  `,

  COUNT: () => gql`
    query CountFonts($filters: FilterFontFields) {
      FontsCount(status: all, filters: $filters)
    }
  `,

  UPDATE: () => gql`
    mutation UpdateFont($_id: ObjectID!, $data: UpdateFontData!) {
      updateFont(_id: $_id, data: $data) {
        _id
        random_id
        google_name
        type
        is_premium
        is_active
      }
    }
  `,

  CREATE: () => gql`
    mutation AddFont(
      $random_id: ID!
      $google_name: String!
      $type: String
      $is_premium: Boolean
      $is_active: Boolean
    ) {
      addFont(
        random_id: $random_id
        google_name: $google_name
        type: $type
        is_premium: $is_premium
        is_active: $is_active
      ) {
        _id
        random_id
        google_name
        type
        is_premium
        is_active
      }
    }
  `,

  DELETE: () => gql`
    mutation DeleteFont($_id: ObjectID!) {
      deleteFont(_id: $_id) {
        ok
      }
    }
  `,
};

export default FontQueries;
